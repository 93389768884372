import React, { useState, useEffect } from 'react';
import { Link } from 'react-router-dom';
import PropTypes from 'prop-types';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import {
  faUserSecret,
  faTerminal,
  faEdit,
  faPlayCircle,
  faCopy,
  faCheck,
  faCaretDown,
  faCaretUp,
  faList,
  faChartLine,
} from '@fortawesome/free-solid-svg-icons';
import { faQuestionCircle } from '@fortawesome/free-regular-svg-icons';
import {
  Modal,
  ModalBody,
  ModalHeader,
  ListGroup,
  ListGroupItem,
  Collapse,
  Badge,
} from 'reactstrap';
import { AutoscalingLabels } from '../../api/functionsApi';

import { FunctionInvocation } from '../FunctionInvocation';
import { FunctionOverviewPanel } from '../FunctionOverviewPanel';
import { ReplicasProgress } from '../ReplicasProgress';

import { Button } from 'reactstrap';

const renderContainerImage = (image) => {
  const imageWithoutTag = image.split(':')[0];
  const parts = imageWithoutTag.split('/');

  if (image.startsWith('ghcr.io/')) {
    return (
      <a href={`https://${image}`} target="_blank" rel="noopener noreferrer">
        {image}
      </a>
    );
  }

  if (parts.length === 2) {
    return (
      <a
        href={`https://hub.docker.com/r/${parts[0]}/${parts[1]}/tags`}
        target="_blank"
        rel="noopener noreferrer"
      >
        {image}
      </a>
    );
  }

  return image;
};

const FunctionDetailSummary = ({
  changeFunctionInvocationTimePeriod,
  fn,
  fnDetail,
  functionInvocationData,
  handleShowBadgeModal,
}) => {
  const toFnLogs = `${fn.name}/logs/`;
  const toFnInvoke = `${fn.name}/invoke/`;

  const tasks = getTasks(fnDetail);

  const runtimeMeta = [
    {
      label: 'Replicas:',
      value: (
        <div style={{ "max-width": '200px' }}>
          <ReplicasProgress scaling={fn.scale.type !== 'disabled'} fn={fn} />
        </div>
      ),
    },
  ];

  if (fnDetail && fnDetail.usage && fnDetail.usage.totalMemoryBytes) {
    runtimeMeta.push({
      label: 'RAM:',
      value: (fnDetail.usage.totalMemoryBytes / 1024 / 1024).toFixed(2) + ' MB',
    });
  }

  if (fnDetail && fnDetail.usage && fnDetail.usage.cpu) {
    let mi = fnDetail.usage.cpu;
    runtimeMeta.push({
      label: 'CPU:',
      value: (mi < 1 ? 1 : mi).toFixed(2) + ' Mi',
    });
  }

  const invocationsIcon = (
    <FontAwesomeIcon
      icon="bolt"
      className="mr-3 mr-lg-2 d-inline-block d-lg-none d-xl-inline-block"
    />
  );

  const fnLogsButton = (
    <Button outline color="secondary" size="xs" tag={Link} to={toFnLogs}>
      <FontAwesomeIcon icon={faTerminal} className="mr-2" />
      <span>Logs</span>
    </Button>
  );

  return (
    <>
      <div className="row pb-3">
        <div className="col-lg-4 pb-3 pb-lg-0 height-lg-360">
          <DeploymentCard
            fn={fn}
            fnDetail={fnDetail}
            invokeLink={toFnInvoke}
          ></DeploymentCard>
        </div>
        <div className="col-lg-4 pb-3 pb-lg-0 height-lg-360">
          <FunctionOverviewPanel
            headerText="Runtime"
            headerIcon={invocationsIcon}
            button={fnLogsButton}
          >
            {functionInvocationData ? (
              <>
                <FunctionOverviewPanel.MetaList list={runtimeMeta} />
                <div className='mt-2'>
                  <FunctionInvocation
                    functionInvocationData={functionInvocationData}
                    changeFunctionInvocationTimePeriod={
                      changeFunctionInvocationTimePeriod
                    }
                  />
                </div>
              </>
            ) : (
              <div className="h-100 d-flex justify-content-center align-items-center">
                <FontAwesomeIcon icon="spinner" spin />
              </div>
            )}
          </FunctionOverviewPanel>
        </div>
        <div className="col-lg-4 height-lg-360">
          <MetadataCard
            fn={fn}
            fnDetail={fnDetail}
            handleShowBadgeModal={handleShowBadgeModal}
          ></MetadataCard>
        </div>
      </div>
      <div className="row">
        <div className="col-lg-6 pb-3 pb-lg-0">
          <ScalingCard fn={fn}></ScalingCard>
        </div>
        <div className="col-lg-6">
          <FunctionOverviewPanel
            headerText="Tasks"
            headerIcon={<FontAwesomeIcon icon={faList} className="mr-3" />}
          >
            <TaskList tasks={tasks}></TaskList>
          </FunctionOverviewPanel>
        </div>
      </div>
    </>
  );
};

const MetadataCard = ({ fn, fnDetail, handleShowBadgeModal }) => {
  const repo = `${fn.gitOwner}/${fn.gitRepo}`;

  const GitIcon = (
    <span>
      <FontAwesomeIcon icon="code-branch" className="mr-3" />
      {fn.gitPrivate && (
        <FontAwesomeIcon icon={faUserSecret} className="mr-3" />
      )}
    </span>
  );

  const GitButton = (
    <Button outline color="secondary" size="xs" onClick={handleShowBadgeModal}>
      <FontAwesomeIcon icon={faEdit} className="mr-2" />
      <span>Set metadata</span>
    </Button>
  );

  const getAnnotationsMeta = (fnDetail) => {
    return fnDetail && fnDetail.annotations
      ? Object.keys(fnDetail.annotations).map((k) => ({
          label: `${k}`,
          value: fnDetail.annotations[k],
        }))
      : [];
  };

  const getLabelsMeta = (fnDetail) => {
    return fnDetail && fnDetail.labels
      ? Object.keys(fnDetail.labels).map((k) => ({
          label: `${k}`,
          value: fnDetail.labels[k],
        }))
      : [];
  };

  const annotationsMeta = getAnnotationsMeta(fnDetail);
  const labelsMeta = getLabelsMeta(fnDetail);

  const gitMeta = [
    {
      label: 'Repository:',
      renderValue() {
        return fn.gitRepoURL ? (
          <a href={fn.gitRepoURL} target="_blank">
            {repo}
          </a>
        ) : (
          <span>Not set</span>
        );
      },
    },
    {
      label: 'SHA:',
      renderValue() {
        return fn.gitSha ? (
          <a href={`${fn.gitRepoURL}/commit/${fn.gitSha}`} target="_blank">
            {fn.gitSha}
          </a>
        ) : (
          <span>Not set</span>
        );
      },
    },
  ];

  return (
    <FunctionOverviewPanel
      headerText="Metadata"
      headerIcon={GitIcon}
      button={GitButton}
      bodyClassName={'height-xl-340'}
    >
      <FunctionOverviewPanel.MetaList
        list={gitMeta}
        sizes={{ xs: 12, sm: 3, md: 2, lg: 5, xl: 4 }}
      />
      <Disclosure
        className="mt-2"
        label={
          <span>
            Annotations <Badge>{annotationsMeta.length}</Badge>
          </span>
        }
        disabled={annotationsMeta.length == 0}
      >
        <div className="py-2">
          {annotationsMeta.length &&
            annotationsMeta.map((meta) => (
              <div key={`annotation-${meta.label}`} className="my-2">
                <span className="is-bold">{meta.label}</span>: {meta.value}
              </div>
            ))}
        </div>
      </Disclosure>
      <Disclosure
        label={
          <span>
            Labels <Badge>{labelsMeta.length}</Badge>
          </span>
        }
        disabled={labelsMeta.length == 0}
      >
        <div className="py-2">
          {labelsMeta.length &&
            labelsMeta.map((meta) => (
              <div key={`label-${meta.label}`} className="my-2">
                <span className="is-bold">{meta.label}</span>: {meta.value}
              </div>
            ))}
        </div>
      </Disclosure>
    </FunctionOverviewPanel>
  );
};

const DeploymentCard = ({ fn, fnDetail, invokeLink }) => {
  let deployMeta = [
    {
      label: 'Name:',
      value: fn.name,
    },
    {
      label: 'Image:',
      value: renderContainerImage(fn.image),
    },
    {
      label: 'Deployed:',
      value: fn.sinceDuration,
    },
    {
      label: 'Status:',
      value: fn.replicas > 0 ? 'Ready' : 'Not Ready',
    },
  ];

  return (
    <FunctionOverviewPanel
      headerText="Deployment"
      headerIcon={<FontAwesomeIcon icon="info-circle" className="mr-3" />}
      button={
        <Button outline color="secondary" size="xs" tag={Link} to={invokeLink}>
          <FontAwesomeIcon icon={faPlayCircle} className="mr-2" />
          <span>Invoke</span>
        </Button>
      }
    >
      <FunctionOverviewPanel.MetaList list={deployMeta} />
      <Disclosure className="mt-2" label="Resources">
        <div>
          <p className="mb-0 is-bold">Requests:</p>
          <div className="ml-3">
            <p className="my-1">
              Memory:{' '}
              {fnDetail.requests && fnDetail.requests.memory
                ? fnDetail.requests.memory
                : 'Not set'}
            </p>
            <p className="my-1">
              CPU:{' '}
              {fnDetail.requests && fnDetail.requests.cpu
                ? fnDetail.requests.cpu
                : 'Not set'}
            </p>
          </div>
        </div>
        <div>
          <p className="mb-0 is-bold">Limits:</p>
          <div className="ml-3">
            <p className="my-1">
              Memory:{' '}
              {fnDetail.limits && fnDetail.limits.memory
                ? fnDetail.limits.memory
                : 'Not set'}
            </p>
            <p className="my-1">
              CPU:{' '}
              {fnDetail.limits && fnDetail.limits.cpu
                ? fnDetail.limits.cpu
                : 'Not set'}
            </p>
          </div>
        </div>
      </Disclosure>
    </FunctionOverviewPanel>
  );
};

const ScalingCard = ({ fn }) => {
  const [infoModalIsOpen, setInfoModalIsOpen] = useState(false);

  const toggleInfoModal = () => setInfoModalIsOpen(!infoModalIsOpen);

  const {scale} = fn

  let metaListContent = [
    {
      label: 'Method:',
      value: scale.type,
    },
    {
      label: 'Scale to zero:',
      value: scale.zero ? scale.zeroDuration : 'disabled',
    },
  ];

  if (scale.type != 'disabled') {
    metaListContent.splice(1, 0, {
      label: 'Target:',
      value: scale.target,
    });
    metaListContent.splice(2, 0, {
      label: 'Proportion',
      value: scale.proportion,
    });
  }

  return (
    <>
      <FunctionOverviewPanel
        headerText="Scaling"
        headerIcon={<FontAwesomeIcon icon={faChartLine} className="mr-3" />}
        button={
          <Button outline color="secondary" size="xs" onClick={toggleInfoModal}>
            <FontAwesomeIcon icon={faEdit} className="mr-2" />
            <span>Configure scaling</span>
          </Button>
        }
      >
        <FunctionOverviewPanel.MetaList list={metaListContent} />
      </FunctionOverviewPanel>
      <ScalingInfoModal
        isOpen={infoModalIsOpen}
        toggle={toggleInfoModal}
      ></ScalingInfoModal>
    </>
  );
};

const TaskList = ({ tasks }) => {
  const [modalState, setModalState] = useState(new Map());

  const toggleModal = (id) => {
    setModalState((oldState) => {
      let newState = { ...oldState };
      newState[id] = !oldState[id];

      return newState;
    });
  };

  return tasks.length ? (
    <ListGroup>
      {tasks &&
        tasks.map((item) => (
          <ListGroupItem
            tag="button"
            action
            key={item.text}
            className="pt-1 pb-1 flex align-items-center"
            onClick={() => toggleModal(item.id)}
          >
            <FontAwesomeIcon
              className="mr-2 text-primary"
              icon={faQuestionCircle}
            />
            {item.text}
            <item.Modal
              isOpen={modalState[item.id]}
              toggle={() => toggleModal(item.id)}
            ></item.Modal>
          </ListGroupItem>
        ))}
    </ListGroup>
  ) : (
    <p>No actions required</p>
  );
};

const Disclosure = ({ disabled, label, children, ...props }) => {
  const [isOpen, setIsOpen] = useState(false);

  const toggle = () => setIsOpen(!isOpen);

  return (
    <div {...props}>
      <div className="flex flex-column">
        <div
          className="py-1 is-bold flex card-header justify-content-space-between align-items-center"
          onClick={disabled ? null : toggle}
          style={{
            backgroundColor: 'rgba(0,0,0,.03)',
            marginLeft: '-1.25rem',
            marginRight: '-1.25rem',
            cursor: disabled ? 'default' : 'pointer',
          }}
        >
          {label}
          {!disabled && (
            <FontAwesomeIcon
              className="ml-2"
              icon={isOpen ? faCaretUp : faCaretDown}
            />
          )}
        </div>
        <Collapse isOpen={isOpen}>{children}</Collapse>
      </div>
    </div>
  );
};

function InfoModal({ isOpen, toggle, headerText, children }) {
  return (
    <Modal size="lg" isOpen={isOpen} toggle={toggle}>
      <ModalHeader toggle={toggle}>{headerText}</ModalHeader>
      <ModalBody>{children}</ModalBody>
    </Modal>
  );
}

const CopyButton = ({ text, children, ...props }) => {
  let [copyCount, setCopyCount] = useState(0);
  let copied = copyCount > 0;

  useEffect(() => {
    if (copyCount > 0) {
      let timeout = setTimeout(() => setCopyCount(0), 1000);
      return () => {
        clearTimeout(timeout);
      };
    }
  }, [copyCount]);

  return (
    <Button
      onClick={() => {
        window.navigator.clipboard.writeText(text).then(() => {
          setCopyCount((count) => count + 1);
        });
      }}
      {...props}
    >
      Copy
      <FontAwesomeIcon className="ml-2" icon={copied ? faCheck : faCopy} />
    </Button>
  );
};

const Code = ({ text }) => (
  <div
    className="mb-3"
    style={{
      whiteSpace: 'pre',
      backgroundColor: 'rgba(0, 0, 0, 0.04)',
      borderRadius: '0.4rem',
    }}
  >
    <div
      className="px-3 py-1 flex justify-content-end border-bottom"
      style={{
        borderColor: 'rgba(0, 0, 0, 0.9)',
        backgroundColor: 'rgba(0, 0, 0, 0.04)',
      }}
    >
      <CopyButton outline size="xs" text={text}>
        <span>Copy</span>
        <FontAwesomeIcon className="ml-2" icon={faCopy} />
      </CopyButton>
    </div>
    <div
      className="p-2 text-monospace"
      style={{ overflowX: 'auto', blockSize: 'fit-content' }}
    >
      {text}
    </div>
  </div>
);

const LimitsInfoModal = ({ ...props }) => {
  const codeExample = limitsConfigurationYAML();

  return (
    <InfoModal {...props} headerText="Memory/CPU limits">
      <p>
        Set memory and CPU limits for functions to prevent them consuming too
        many resources.
      </p>
      <p>Example:</p>
      <Code text={codeExample} />
      <Button
        color="primary"
        size="sm"
        tag="a"
        href="https://docs.openfaas.com/reference/yaml/#function-memorycpu-limits"
      >
        See documentation
      </Button>
    </InfoModal>
  );
};

const RequestsInfoModal = ({ ...props }) => {
  const codeExample = requestsConfigurationYAML();

  return (
    <InfoModal {...props} headerText="Memory/CPU request">
      <p>
        Memory and CPU requests should always be set for accurate scheduling and
        scaling.
      </p>
      <p>Example:</p>
      <Code text={codeExample} />
      <Button
        color="primary"
        size="sm"
        tag="a"
        href="https://docs.openfaas.com/reference/yaml/#function-memorycpu-limits"
      >
        See documentation
      </Button>
    </InfoModal>
  );
};

const ScaleToZeroInfoModal = ({ ...props }) => {
  const codeExample = scaleToZeroConfigurationYAML();

  return (
    <InfoModal {...props} headerText="Scale to Zero">
      <p>
        Reduce CPU and memory usage by scaling idle functions to zero replicas.
      </p>
      <p>
        Scale to zero is opt-in and needs to be enabled per function. This is
        achieved through adding labels to the stack.yml file for a function.
      </p>
      <p>Example:</p>
      <Code text={codeExample} />
      <Button
        color="primary"
        size="sm"
        tag="a"
        href="https://docs.openfaas.com/openfaas-pro/scale-to-zero/"
      >
        See documentation
      </Button>
    </InfoModal>
  );
};

const HorizontalScalingInfoModal = ({ ...props }) => {
  const codeExample = autoscalingConfigurationYAML();

  return (
    <InfoModal {...props} headerText="Autoscaling">
      <p>
        Scale functions horizontally between a minimum and maximum number of
        replicas.
      </p>
      <p>
        This is achieved through adding labels to the stack.yml file for a
        function.
      </p>
      <p>Example:</p>
      <Code text={codeExample} />
      <Button
        color="primary"
        size="sm"
        tag="a"
        href="https://docs.openfaas.com/architecture/autoscaling/"
      >
        See documentation
      </Button>
    </InfoModal>
  );
};

const ScalingInfoModal = ({ ...props }) => {
  return (
    <InfoModal {...props} headerText="Autoscaling">
      <h6>Horizontal scaling</h6>
      <p>
        Scale functions horizontally between a minimum and maximum number of
        replicas.
      </p>
      <p>
        This is achieved through adding labels to the stack.yml file for a
        function.
      </p>
      <p>Example:</p>
      <Code text={autoscalingConfigurationYAML()} />
      <h6>Scale to zero</h6>
      <p>
        Reduce CPU and memory usage by scaling idle functions to zero replicas.
      </p>
      <p>
        Scale to zero is opt-in and needs to be enabled per function. This is
        achieved through adding labels to the stack.yml file for a function.
      </p>
      <p>Example:</p>
      <Code text={scaleToZeroConfigurationYAML()} />
      <Button
        color="primary"
        size="sm"
        tag="a"
        href="https://docs.openfaas.com/architecture/autoscaling/"
      >
        See documentation
      </Button>
    </InfoModal>
  );
};

const limitsConfigurationYAML = () => `    limits:
      memory: 40Mi
      cpu: 100m
`;

const requestsConfigurationYAML = () => `    requests:
      memory: 20Mi
      cpu: 100m
`;

const scaleToZeroConfigurationYAML = () => `    labels:
      com.openfaas.scale.zero: true
      com.openfaas.scale.zero-duration: 5m
`;

const autoscalingConfigurationYAML = () => `    labels:
      com.openfaas.scale.max: 10
      com.openfaas.scale.target: 5
      com.openfaas.scale.type: capacity 
      com.openfaas.scale.target-proportion: 1.0
`;

function hasKey(object, key) {
  return key in object;
}

function hasAnyKey(object, keys) {
  for (let key of keys) {
    if (hasKey(object, key)) return true;
  }

  return false;
}

function getTasks(fn) {
  const tasks = [];

  if (!fn.requests || !fn.requests.cpu) {
    tasks.push({
      id: 'memory-limits',
      text: 'Configure memory and CPU requests',
      Modal: RequestsInfoModal,
    });
  }

  if (!fn.limits) {
    tasks.push({
      id: 'cpu-limits',
      text: 'Configure memory and CPU limits',
      Modal: LimitsInfoModal,
    });
  }

  let labels = fn.labels || {};

  if (!hasKey(labels, AutoscalingLabels.Zero)) {
    tasks.push({
      id: 'scale-to-zero',
      text: 'Configure scale to zero',
      Modal: ScaleToZeroInfoModal,
    });
  }

  const autoscalingLabels = [
    AutoscalingLabels.Max,
    AutoscalingLabels.Min,
    AutoscalingLabels.Type,
    AutoscalingLabels.Target,
    AutoscalingLabels.Proportion,
  ];

  if (!hasAnyKey(labels, autoscalingLabels)) {
    tasks.push({
      id: 'autoscaling',
      text: 'Configure autoscaling',
      Modal: HorizontalScalingInfoModal,
    });
  }

  return tasks;
}

FunctionDetailSummary.propTypes = {
  fn: PropTypes.object.isRequired,
  fnDetail: PropTypes.object.isRequired,
  handleShowBadgeModal: PropTypes.func.isRequired,
};

export { FunctionDetailSummary };
